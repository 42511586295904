import detailDefault from '../../detailDefault';

export default class detailAccounts extends detailDefault {
    load(sAction, data) {
        sAction.dataSet(data.prefix + '/readonly', true);
        const account = sAction.dataGet(data.prefix+'/fields/acm_invoices_acm_invoices_lines_name/value');
        if (account != null || account != '') {
            sAction.dataSet(data.prefix+'/fields/currency_id/def/readonly', true);
        }
        if (sAction.dataGet('view/readonly')) { // readonly pro rightPanel pokud detail je readonly
            sAction.dataSet(data.prefix + '/readonly', true);
        }
    }
    update(sAction, data) {
        const fields = sAction.dataGet(data.prefix+'/fields');
        const cenaBezDphKus = fields.getIn(['cena_bez_dph_kus', 'value']);
        const mnozstvi = fields.getIn(['mnozstvi', 'value']);
        const slevaAbs = fields.getIn(['sleva_hodnota', 'value']);
        const sazbaDph = fields.getIn(['sazba_dph', 'value']);
        let slevaProcento = fields.getIn(['sleva_procento', 'value']);

        let slevaHodnota = '';

        if (cenaBezDphKus) {
            var cenabezdph = sAction.normalizePrice(cenaBezDphKus) * sAction.normalizePrice(mnozstvi);
            if (slevaProcento != '' && slevaProcento) {
                const cenabezdphorig = cenabezdph;
                cenabezdph -= (sAction.normalizePrice(slevaProcento) / 100) * cenabezdph;
                const cenasleva = cenabezdphorig - cenabezdph;
                slevaHodnota = cenasleva;
            }
        }

        if (slevaAbs != '' && slevaAbs) {
            cenabezdph -= slevaAbs;
            slevaProcento = '';
        }

        const dphkoef = sAction.normalizePrice(sazbaDph / 100) + 1;
        const cenasdph = cenabezdph * dphkoef;
        const dph = cenasdph - cenabezdph;

        sAction.dsClear();
        sAction.dsAdd('set', data.prefix+'/fields/cena_bez_dph_celkem/value', cenabezdph);
        sAction.dsAdd('set', data.prefix+'/changes/fields/cena_bez_dph_celkem', cenabezdph);
        sAction.dsAdd('set', data.prefix+'/fields/cena_s_dph_celkem/value', cenasdph);
        sAction.dsAdd('set', data.prefix+'/changes/fields/cena_s_dph_celkem', cenasdph);
        sAction.dsAdd('set', data.prefix+'/fields/cena_dph_celkem/value', dph);
        sAction.dsAdd('set', data.prefix+'/changes/fields/cena_dph_celkem', dph);
        sAction.dsAdd('set', data.prefix+'/fields/sleva_hodnota/value', slevaHodnota);
        sAction.dsAdd('set', data.prefix+'/changes/fields/sleva_hodnota', slevaHodnota);
        sAction.dsAdd('set', data.prefix+'/fields/sleva_procento/value', slevaProcento);
        sAction.dsAdd('set', data.prefix+'/changes/fields/sleva_procento', slevaProcento);

        const cenasdphkus = cenaBezDphKus * dphkoef;
        const dphkus = cenasdphkus - cenaBezDphKus;
        sAction.dsAdd('set', data.prefix+'/fields/cena_dph_kus/value', dphkus);
        sAction.dsAdd('set', data.prefix+'/changes/fields/cena_dph_kus', dphkus);
        sAction.dsAdd('set', data.prefix+'/fields/cena_s_dph_kus/value', cenasdphkus);
        sAction.dsAdd('set', data.prefix+'/changes/fields/cena_s_dph_kus', cenasdphkus);

        sAction.dsProcess();
    }
}
