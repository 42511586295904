/**
 *
 * @param {*} params
 * @returns
 */
export default function oppChangeState(params) {
    const reqFields = [];
    if (params.newState === 'Value Proposition') {
        reqFields.push('amount');
    }

    let fieldsValid = true;

    switch (params.newState) {
        case 'Closed Lost':
            reqFields.push('konkurence_c');
        case 'Suspended': // eslint-disable-line
        case 'Closed canceled client':
        case 'Closed canceled me':
            // reqFields.push('close_reason');
            reqFields.push('duvod_uzavreni_c');
            this.dataSet(params.prefix+'/fields/duvod_uzavreni_c/def/readonly', false);
        case 'Qualification': // eslint-disable-line
        case 'Value Proposition':
        case 'Negotiation/Review':
        case 'Closed Won':
            reqFields.forEach((field) => {
                if (!this.dataGet(params.prefix + '/fields/' + field + '/value')) {
                    fieldsValid = false;
                    this.dataSet(params.prefix + '/fields/' + field + '/customClass', 'error');
                    this.dataSet(params.prefix + '/fields/' + field + '/def/required', true);
                }
            });

            const reqRet = this.requiredFieldsCheck(params.prefix); // eslint-disable-line

            reqFields.forEach((field) => {
                if (!this.dataGet(params.prefix + '/fields/' + field + '/value')) {
                    this.dataSet(params.prefix + '/fields/' + field + '/def/required', false);
                }
            });
            if (!fieldsValid || !reqRet) {
                this.error(this.translate('LBL_ERR_FILL_RAQUIRED_FIELDS', 'Opportunities'));
                return;
            }

            this.load();
            params.action = 'opp_change_state';
            this.rest.post('customAction', params, (resp) => {
                this.unLoad();
                if (resp.errCode === 'OK') {
                    this.saveRecord(params);
                } else {
                    this.error(this.translate(resp.errMsg, 'Opportunities'));
                }
            });
            break;
    }
}
