/**
 *
 * @param {*} data
 */
export default function emailReplyMonitoring(data) {
    const emailData = this.dataGet('view/customData').toJS();
    emailData.replyMode = data.replyMode;
    emailData.name = data.name;

    // parent_type
    // parent_id
    // parent_name
    emailData.parent_id = data.id;
    emailData.parent_type = data.module;
    emailData.parent_name = data.name;
    emailData.to_addrs = data.toAddrs;

    // emailData.emailTemplate // Sablona
    emailData.description_html = data.descriptionHtml; // Telo emailu

    emailData.setStavToDone = true;
    emailData.way = 'view';

    this.openEmailPopup(emailData);
}
