/**
 * @param {object} params
 * @this sAction
 * @button DetailView/Opportunities
 */
export default function openOpportunity(params) {
    this.dsClear();
    // pro ZLINER se otevira na Prospecting
    this.dsAdd('set', `${params.prefix}/fields/sales_stage/value`, 'Prospecting');
    this.dsAdd('set', `${params.prefix}/changes/fields/sales_stage`, 'Prospecting');
    this.dsProcess();

    // this.saveField(params);
    params.canSave = true;
    this.saveRecord(params);
}
