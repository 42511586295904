import detailDefault from '../../detailDefault';

export default class detailacm_vehicle_registration extends detailDefault { // eslint-disable-line
    load(sAction, data) { // eslint-disable-line

    }

    update(sAction, data) {
        if (data.field === 'acm_vehiclbe64catalog_ida') {
            const model = sAction.dataGet(data.prefix+'/fields/model/value');
            const type = sAction.dataGet(data.prefix+'/fields/type/value');
            const brand = sAction.dataGet(data.prefix+'/fields/brand/value');
            if (!model || !type || !brand) {
                this.getVehicleCData(sAction, data, (response) => {
                    if (response) {
                        sAction.dsClear();
                        if (!model) {
                            sAction.dsAdd('set', data.prefix+'/fields/model/value', response.message.data.model);
                            sAction.dsAdd('set', data.prefix+'/changes/fields/model', response.message.data.model);
                        }
                        if (!type) {
                            sAction.dsAdd('set', data.prefix+'/fields/type/value', response.message.data.type);
                            sAction.dsAdd('set', data.prefix+'/changes/fields/type', response.message.data.type);
                        }
                        if (!brand) {
                            sAction.dsAdd('set', data.prefix+'/fields/brand/value', response.message.data.brand);
                            sAction.dsAdd('set', data.prefix+'/changes/fields/brand', response.message.data.brand);
                        }
                        sAction.dsProcess();
                    }
                });
            }
        }
    }

    /**
     * @param {Object} sAction
     * @param {*} data
     * @param {Function} callback
     */
    getVehicleCData(sAction, data, callback) {
        const vehicleId = data.value.id;
        if (vehicleId) {
            sAction.rest.post('getVehicleCData', {id: vehicleId}, (response) => {
                callback(response);
            });
        }
    }

    /**
     *
     * @param {Object} sAction
     * @param {*} data
     */
    updateDetailLine(sAction, data) { // eslint-disable-line

    }
}
