import detailDefault from '../../detailDefault';

export default class detailacm_vehicle_catalog extends detailDefault { // eslint-disable-line
    load(sAction, data) { // eslint-disable-line
        if (sAction.dataGet(data.prefix + '/id')) {
            const brand = sAction.dataGet(data.prefix + '/fields/brand/value');
            const model = sAction.dataGet(data.prefix + '/fields/model/value');
            const name = brand + ' ' + model;
            sAction.dataSet(data.prefix + '/fields/name/value', name);
            sAction.dataSet(data.prefix + '/name', name);
        }
    }

    update(sAction, data) { // eslint-disable-line

    }

    /**
     *
     * @param {Object} sAction
     * @param {*} data
     */
    updateDetailLine(sAction, data) { // eslint-disable-line

    }
}
