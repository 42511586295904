import moment from 'moment';
import {object} from 'prop-types';
import sAction from 'sAction';

/**
 * calGetEvents
 * @param {bool} forced
 * @param {string} way
 * @param {string} userID
 * @param {function} _callback
 */
export default function calGetEvents(forced, way, userID, _callback) {
    const self = this;
    const prefix = way?way+'/data/options':'calendar/filters';
    if (!self.calEvents.cssSelector) {
        // TTT - tohle je kvuli dashletu (dost velka prasarna)
        // || $("#"+self.calEvents.cssSelector).length === 0
        return;
    }

    if (forced) {
        const params = {'user_id': userID, 'date_range': this.calEvents.date_range, 'params': {'sharedView': self.calEvents.sharedView}};

        this.rest.post('calActivity', params, function(data) {
            // $("#"+self.calEvents.cssSelector).fullCalendar("render");

            data.eventData.calendars.forEach((cal) => {
                const pomEvents = {'Meetings': [], 'Calls': [], 'Tasks': [], 'Events': []};

                cal.Meetings.forEach((act) => {
                    pomEvents.Meetings.push(act);
                });

                cal.Calls.forEach((act) => {
                    pomEvents.Calls.push(act);
                });

                cal.Tasks.forEach((act) => {
                    pomEvents.Tasks.push(act);
                });

                self.calEvents.calendars[cal.id] = {id: cal.id, events: pomEvents};
            });

            setEvents(self, userID, {prefix: prefix}, (data) => {
                _callback(data);
            });
        });
    } else {
        setEvents(self, userID, {prefix: prefix}, (data) => {
            _callback(data);
        });
    }
}

/**
 * setEvents
 * @param {class} self
 * @param {string} userID
 * @param {any} attrs
 * @param {function} _callback
 */
export function setEvents(self, userID, attrs, _callback) {
    // na widgetu sa sem dostane my_callendar misto user id netusim proc toto je jenom quickfix
    if (userID === 'my_calendar') {
        userID = self.dataGet('conf/user/id');
    }

    if (userID === null) {
        // nevim proc ale u libora se to zacyklilo na sdilenem kalendari
        // totozabrání zacyklení ale kalendář vyrendruje jednou navíc a zůstane loading
        // stačilo změnit nastavení sdíleného kalendáře a vše funguje (vybrat lidi a uložit)
        console.warn('[ABORT] reload userID is null');

        return;
    }

    const usersCalendar = self.calEvents.calendars[userID];
    if (usersCalendar) {
        setEventsCalendar(self, attrs, usersCalendar, (data) => {
            if (_callback) {
                _callback(data);
            } else {
                self.calPlanReload();
            }
        });
    } else {
        self.calPlanReload();
    }
}

/**
 * setEventsCalendar
 * @param {class} self
 * @param {any} attrs
 * @param {any} cal
 * @param {function} _callback
 */
export function setEventsCalendar(self, attrs, cal, _callback) {
    let filters = self.dataGet(attrs.prefix);

    if (!filters) {
        filters = {
            showMeetings: true,
            showCalls: true,
            showTasks: true,
            showCompleted: false,
            meetStates: new Set(),
            callStates: new Set(),
            taskStates: new Set(),
        };
    } else if (!(filters instanceof object)) {
        filters = Object.fromEntries(filters); // widgety to posilaji v mape...
    }
    const events = [];
    let eventsPom = null;

    if (attrs.widget) {
        eventsPom = self.dataGet(attrs.prefix+'/eventData');
    } else {
        eventsPom = cal.events;
    }

    if (filters?.showMeetings) {
        eventsPom.Meetings.forEach((ev) => {
            if (!filters.showCompleted) {
                if (ev.status !== 'Planned') {
                    return;
                }
            }

            if (filters.meetStates && filters.meetStates.size > 0) {
                if (!filters.meetStates.includes(ev.status)) {
                    return;
                }
            }
            const allDay = parseInt(ev.all_day) === 1 ? true: false;
            events.push(setEventStyle({
                id: ev.id,
                title: ev.name,
                description: ev.description,
                parent_id: ev.parent_id,
                parent_name: ev.parent_name,
                parent_type: ev.parent_type,
                invites: ev.invites,
                invitesCnt: ev.invites ? (ev.invites?.Contacts?.length + ev.invites?.Leads?.length + ev.invites?.Users?.length) : 0,
                date: ev.date_start,
                start: ev.date_start,
                end: ev.date_end,
                act_type: 'Meetings',
                status: ev.status,
                allDay: allDay,
                orderLines: ev.orderLines,
                assigned_user_id: ev.assigned_user_id,
                assigned_user_name: ev.assigned_user_name,
                meetType: ev.meetType,
                type: ev.type,
            }));
        });
    }

    if (filters?.showCalls) {
        eventsPom.Calls.forEach((ev) => {
            if (!filters.showCompleted) {
                if (ev.status !== 'Planned') {
                    return;
                }
            }

            if (filters.callStates && filters.callStates.size > 0) {
                if (!filters.callStates.includes(ev.status)) {
                    return;
                }
            }
            const allDay = parseInt(ev.all_day) === 1 ? true: false;
            events.push(setEventStyle({
                id: ev.id,
                title: ev.name,
                description: ev.description,
                parent_id: ev.parent_id,
                parent_name: ev.parent_name,
                parent_type: ev.parent_type,
                invites: ev.invites,
                invitesCnt: ev.invites ? (ev.invites?.Contacts?.length + ev.invites?.Leads?.length + ev.invites?.Users?.length) : 0,
                date: ev.date_start,
                start: ev.date_start,
                end: ev.date_end,
                act_type: 'Calls',
                status: ev.status,
                allDay: allDay,
            }));
        });
    }

    if (filters?.showTasks) {
        const tasksLastDay = self.dataGet(attrs.prefix + '/../setting/tasksLastDay');

        eventsPom.Tasks.forEach((ev) => {
            if (!filters.showCompleted) {
                if (ev.status === 'Completed') {
                    return;
                }
            }

            if (filters.taskStates && filters.taskStates.size > 0) {
                if (!filters.taskStates.includes(ev.status)) {
                    return;
                }
            }

            let dateStartPom = ev.date_start;
            if (tasksLastDay && ev.date_end) {
                if (ev.date_start.substring(0, 10) !== ev.date_end.substring(0, 10)) {
                    const dateObj = moment(ev.date_end).subtract(30, 'minutes');
                    dateStartPom = dateObj.format('YYYY-MM-DD HH:mm:ss');
                }
            }
            const allDay = parseInt(ev.all_day) === 1 ? true: false;
            events.push(setEventStyle({
                id: ev.id,
                title: ev.name,
                description: ev.description,
                parent_id: ev.parent_id,
                parent_name: ev.parent_name,
                parent_type: ev.parent_type,
                invites: ev.invites,
                invitesCnt: ev.invites ? (ev.invites?.Contacts?.length + ev.invites?.Leads?.length + ev.invites?.Users?.length) : 0,
                date: ev.date_start,
                start: dateStartPom,
                end: ev.date_end,
                act_type: 'Tasks',
                status: ev.status,
                allDay: allDay,
            }));
        });
    }

    _callback(events);
}

/**
 * setEventStyle
 * @param {object} event
 * @returns {object}
 */
export function setEventStyle(event) {
    const colors = sAction.getActivityColors(event.act_type, event.meetType);
    event.backgroundColor = colors.backgroundColor;
    event.eventBackgroundColor = colors.eventBackgroundColor; // kvuli mesicnimu pohledu
    event.borderColor = colors.borderColor;
    event.textColor = colors.textColor;
    return event;
}
