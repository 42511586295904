/**
 * @param {object} data
 * @this sAction
 * @TODO rest.post => rest.fetchData
 * @button DetailView/Quotes
 */
export default function acmQueueProcessingCreateOpp(data) {
    const params = {action: 'acm_queue_processing_create_opp', record: data.id};

    this.load();
    this.rest.post('customAction', params, (resp) => {
        if (resp.status) {
            this.href('#detail/Opportunities/' + resp.message.data.record);
        } else {
            this.unLoad();
            this.alert(resp.errorMessage.text);
        }
    });
}
